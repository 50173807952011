import React, { useState, useEffect, Fragment, useRef } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { initiate1xUsageBookingValidator, isValid1xUsage } from '../../ducks/1xUsageBooking.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  FrequentlyAskedQuestions,
  ReviewsGrid,
  IconLocation,
  Reviews,
  ReviewRating,
  SecondaryButton,
  IconFavouriteHeart,
  IconSpinner,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  updateBookmark,
  // initiate1xUsageBookingValidator,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';

import css from './ListingPage.module.css';
import SectionRiverData from './SectionRiverData';
import SectionWeatherForecast from './SectionWeatherForecast';
import { objectOf } from 'prop-types';
import loadable from '@loadable/component';
import RareWatersPoster from '../AboutPage/Group-372.webp';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

const { Money } = sdkTypes;

const CustomListingImageGallery = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomListingImageGallery" */ './CustomListingImageGallery/CustomListingImageGallery'
    ),
  { ssr: false }
);

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onUpdateBookmark,
    listingsOfLastBookings,
    updateBookmarkInProgress,
    dailyPrices,
    availableSlots,
    listingUsgs,
    fetchListingUsgsInProgress,
    fetchListingUsgsError,
    weatherData,
    fetchWeatherDataInProgress,
    fetchWeatherDataError,
    fetchAvailableSlotsInProgress,
    fetchReviewsInProgress,
    features,
    initiate1xUsageBookingValidator,
    isValid1xUsage,
  } = props;

  const videoRef = useRef(null);

  const divRef = useRef(null);

  const [bookmarked, setBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [divHeight, setDivHeight] = useState(0);

  const setBookmarkButtonContent = () => {
    const currentUserId = currentUser?.id?.uuid;
    if (!currentUserId) {
      return (
        <Fragment>
          <IconFavouriteHeart /> Login to bookmark items
        </Fragment>
      );
    } else if (updateBookmarkInProgress) {
      return <Fragment>Updating...</Fragment>;
    } else if (bookmarked) {
      return (
        <Fragment>
          <IconFavouriteHeart alt /> Bookmarked
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <IconFavouriteHeart /> Bookmark this listing
        </Fragment>
      );
    }
  };

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  useEffect(() => {
    if (currentUser) {
      initiate1xUsageBookingValidator(currentUser?.id?.uuid);
    }
  }, [currentUser]);

  const showBookmarkButton = () =>
    !currentUser || (currentUser?.id?.uuid ?? '') !== (currentListing?.author?.id?.uuid ?? '');

  useEffect(() => {
    if (currentListing) {
      const count = currentListing?.attributes?.publicData?.bookmarkCount ?? 0;
      setBookmarkCount(count || 0);
    }
  }, [currentListing]);

  useEffect(() => {
    if (currentUser && currentListing) {
      const listingId = currentListing?.id?.uuid;
      if (listingId) {
        setBookmarked(
          (currentUser.attributes.profile.publicData.favoriteIds || []).some(
            child => child === listingId
          )
        );
      }
    }
  }, [currentUser, currentListing]);

  //Note: Fix:
  useEffect(() => {
    if (divRef?.current) {
      const height = divRef?.current?.clientHeight;
      setDivHeight(height);
    }
  }, [divRef?.current]);

  const onClickBookmark = () => {
    if (!updateBookmarkInProgress) {
      if (currentUser) {
        const currentUserId = currentUser.id;
        onUpdateBookmark(!bookmarked, listingId, currentUserId, config);
      } else {
        const redirection_link = location?.pathname ?? '';
        history?.push(`/login?redirection_link=${redirection_link}`);
      }
    }
  };

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const richTextEditor = currentListing?.attributes?.publicData?.richText;
  const locationAddress = currentListing?.attributes?.publicData?.location?.address;
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const riverData = currentListing?.attributes?.publicData?.riverData?.riverData ?? null;
  const isRiverDataValid =
    riverData?.value?.timeSeries.length > 0 || riverData?.ResultList?.length > 0 ? true : false;
  // const weatherData = currentListing?.attributes?.publicData?.weatherData?.forecast ?? null;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };
  const unitType = currentListing?.attributes?.publicData?.unitType;
  const pricePerUnit = currentListing?.attributes?.price;
  const campingFee = currentListing?.attributes?.publicData?.campingFee;
  const lodgingFee = currentListing?.attributes?.publicData?.lodgingFee;
  const lodgingNotes = currentListing?.attributes?.publicData?.lodgingNotes;

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const faqItems = [
    {
      question: 'What is RareWaters?',
      answer:
        'RareWaters was born out of frustration with overcrowded rivers and the mismanagement of our favorite public fly fishing spots. As more anglers packed into the same stretches of water, fish became over-pressured, and the experience lost its magic. We set out to change that by opening up more water for more people.<br/><br/>With an exclusive membership, you’ll gain access to private ranches and premier fishing waters—without the crowds or hassle. Join today and experience fly fishing the way it was meant to be!',
    },
    {
      question: 'What hours can I fish?',
      answer:
        'Each landowner sets their own fishing hours, with most allowing access from sunrise to sunset. Some properties may have different timeframes, which are clearly listed on their property pages in the "Know Before You Go" section.',
    },
    {
      question: 'Will I get the water all to myself?',
      answer:
        'We limit the number of anglers on each property, typically to 3-4 anglers per mile, though this varies by location. Be sure to check the property listing page for the specific limit. For example, if your group has 2 anglers and the maximum is 4, there’s a chance that 2 other anglers from a different group may also book that day. However, if you reserve all available rod spots, your group will have exclusive access to the property.',
    },
    {
      question: 'How do I know where to go after I booked?',
      answer:
        'After your payment is received, you’ll get a booking confirmation email. Within 7 days of your reservation, we’ll send you a property map via email, including GPS coordinates, directions, parking details, camping information, and more. We highly recommend saving the GPS coordinates to your smartphone before leaving cell service, as many of our properties are remote working ranches with limited connectivity. For the best navigation, we suggest using Google Maps.',
    },
    {
      question: 'What are your booking policies?',
      answer:
        'Great question! Here are a few key policies to know before your trip:<br/><br/><b>Cancellation Policy</b><br/>• <b>3+ days before your trip:</b> Full refund.<br/>• <b>3 days or less before your trip:</b> No refund.<br/>• <b>Bookings made within 3 days of request date:</b> If canceled, no refund or credit will be issued.<br/><br/>By booking a RareWaters property, you agree to this cancellation policy. Please note that RareWaters is <b>not responsible</b> for weather, river flows, or fishing conditions. Anglers must determine these factors on their own, and refunds/credits will <b>not</b> be issued due to conditions. In special circumstances, RareWaters staff may override this policy at their discretion.<br/><br/><b>Maximum Angler Policy</b><br/>If your group does not meet the maximum angler limit for a property, you may be fishing alongside other anglers. Each property\'s maximum is listed on its booking page. Please practice good fishing etiquette—give fellow anglers space and share different stretches of the water.<br/><br/><b>Leave No Trace Policy</b><br/>All anglers must follow our <b>"Leave No Trace"</b> policy:<br/>• Leave the property better than you found it—if you see trash, pack it out.<br/>• Respect the land, livestock, and watershed to help preserve access.<br/>• <b>Trespassing is strictly prohibited—</b>violators will be prosecuted to the fullest extent of the law.<br/><br/>By booking a trip through RareWaters, you agree to this policy.<br/>Let us know if you have any questions, and happy fishing! 🎣',
    },
  ];

  const totalAverage = Math.round(
    reviews.length > 0
      ? reviews.reduce((acc, r) => {
          return acc + r.attributes.rating;
        }, 0) / reviews.length
      : 0
  );

  // if (!history.location.search) {
  //   window.location.replace(history.location.pathname + '?v=1');
  // }

  const unescape = {
    '\uff1e': '>',
    '\uff1c': '<',
  };

  const monthlyTimeSlotsKeys = Object.keys(monthlyTimeSlots || {});
  const guestCounts = monthlyTimeSlotsKeys
    ?.map(key => {
      return monthlyTimeSlots[key]?.timeSlots?.data?.data;
    })
    ?.flatMap(item => item);

  const maxGuestCount =
    guestCounts && Math.max(...guestCounts?.map(guestCount => guestCount?.attributes?.seats));

  const playAboutVideo = () => {
    setVideoPlaying(true);
  };

  const handleVideoLoaded = () => {
    if (isVideoPlaying && videoRef && videoRef.current) {
      videoRef.current.play();
    }
  };

  const anglersAlsoTitle = intl.formatMessage({
    id: 'ListingPageCarousel.reviewsGridAnglersAlsoTitle',
  });

  // const stickyRef = useRef(null);

  // useEffect(() => {
  //   const stickyElement = stickyRef.current;
  //   const initialOffset = stickyElement.offsetTop;

  //   const handleScroll = () => {
  //     console.log(window.pageYOffset)
  //     if (window.pageYOffset >= 436) {
  //       stickyElement.style.position = 'fixed';
  //       stickyElement.style.top = '0';
  //       stickyElement.style.right= "0"
  //     } else {
  //       stickyElement.style.position = 'static';
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperRoot}>
          <CustomListingImageGallery listing={currentListing} />
          <div className={css.contentWrapperForProductLayout}>
            <div className={css.mainColumnForProductLayout}>
              {currentListing.id ? (
                <ActionBarMaybe
                  className={css.actionBarForProductLayout}
                  isOwnListing={isOwnListing}
                  listing={currentListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingPathParamType,
                    tab: listingTab,
                  }}
                />
              ) : null}

              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>

              <div className={css.locationAddressContainer}>
                <IconLocation className={css.locationIcon} />
                <Link to="#listing-map" className={css.locationAddress}>
                  {locationAddress}
                </Link>
              </div>
              <div className={css.productReviewsContainer}>
                {fetchReviewsInProgress ? (
                  <Skeleton width={'100%'} />
                ) : reviews.length > 0 ? (
                  <>
                    <ReviewRating rating={totalAverage} className={css.productReviewRating} />
                    <div className={css.reviewsText}>
                      <Link to="#listing-reviews">
                        <FormattedMessage
                          id="ListingPage.seeReviews"
                          values={{ count: reviews.length }}
                        />
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className={css.reviewsTextv2}>
                    <FormattedMessage id="ListingPage.noReviews" />
                  </div>
                )}
              </div>
              <hr style={{ opacity: '0.5' }} />

              {maxGuestCount && Number.isFinite(maxGuestCount) ? (
                <div className={css.priceContainer}>
                  <strong>{maxGuestCount}</strong>
                  <div className={css.perUnit}>Anglers Maximum</div>
                </div>
              ) : null}

              {pricePerUnit ? (
                <div className={css.priceContainer}>
                  <strong>Guest fee:</strong>
                  <div className={css.perUnit}>
                    <FormattedMessage
                      id="OrderPanel.perUnit"
                      values={{ unitType, price: formatMoney(intl, price) }}
                    />
                  </div>
                </div>
              ) : null}

              {campingFee ? (
                <div className={css.priceContainer}>
                  <strong>
                    Camping{' '}
                    {campingFee.campingPerDayPerPerson === 'perPerson' ? 'per guest' : 'per day'}:
                  </strong>
                  <div className={css.perUnit}>
                    starting at {formatMoney(intl, new Money(campingFee.amount, 'USD'))}
                    <span style={{ marginLeft: '3px' }}>
                      {campingFee.campingPerDayPerPerson === 'perPerson' ? 'per guest' : 'per day'}
                    </span>
                  </div>
                </div>
              ) : null}

              {lodgingFee ? (
                <div className={css.priceContainer}>
                  <strong>
                    Lodging{' '}
                    {lodgingFee.lodgingPerDayPerPerson === 'perPerson' ? 'per guest' : 'per day'}:
                  </strong>
                  <div className={css.perUnit}>
                    starting at {formatMoney(intl, new Money(lodgingFee.amount, 'USD'))}
                    <span style={{ marginLeft: '3px' }}>
                      {lodgingFee.lodgingPerDayPerPerson === 'perPerson' ? 'per guest' : 'per day'}
                    </span>
                  </div>
                </div>
              ) : null}

              {lodgingNotes ? (
                <div className={css.lodgingNotesContainer}>
                  <strong>Lodging available:</strong>
                  <div
                    className={css.listingOverview}
                    id="listing-overview"
                    dangerouslySetInnerHTML={{
                      __html: lodgingNotes?.replace(/[\uff1c\uff1e]/g, ch => unescape[ch]),
                    }}
                  ></div>
                </div>
              ) : null}

              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, scope = 'public' } = config;
                const value =
                  scope === 'public'
                    ? publicData[key]
                    : scope === 'metadata'
                    ? metadata[key]
                    : null;
                const hasValue = value !== null;

                return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [
                      ...pickedElements,
                      <SectionMultiEnumMaybe
                        id={config?.key}
                        key={key}
                        heading={config?.showConfig?.label}
                        options={createFilterOptions(enumOptions)}
                        selectedOptions={value}
                      />,
                    ]
                  : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                      ...pickedElements,
                      <SectionTextMaybe
                        key={key}
                        heading={config?.showConfig?.label}
                        text={value}
                      />,
                    ]
                  : pickedElements;
              }, [])}

              <div id="listing-nav" className={css.listingNavContainer}>
                <ul className={css.listingNav}>
                  <li>
                    <Link to="#listing-overview">Overview</Link>
                  </li>
                  {richTextEditor ? (
                    <li>
                      <Link to="#listing-before-you-go">Before You Go</Link>
                    </li>
                  ) : null}

                  <li>
                    <Link to="#listing-river-data">River Data</Link>
                  </li>
                  <li>
                    <Link to="#listing-weather-forecast">Weather Forecast</Link>
                  </li>
                  {reviews && reviews?.length > 0 ? (
                    <li>
                      <Link to="#listing-reviews">Reviews</Link>
                    </li>
                  ) : null}

                  <li>
                    <Link to="#listing-faqs">FAQs</Link>
                  </li>
                </ul>
                <hr />
              </div>

              <div
                className={css.listingOverview}
                id="listing-overview"
                dangerouslySetInnerHTML={{
                  __html: description?.replace(/[\uff1c\uff1e]/g, ch => unescape[ch]),
                }}
              ></div>
              {richTextEditor ? (
                <section id="listing-before-you-go" className={css.knowBeforeGoContainer}>
                  <p className={css.resources}>
                    <FormattedMessage id="ListingPage.resources" />
                  </p>
                  <p className={css.title}>
                    <FormattedMessage id="ListingPage.knowBeforeYouGo" />
                  </p>
                  <p className={css.resourcesMessage}>
                    <FormattedMessage id="ListingPage.resourcesMessage" />
                  </p>
                  <div className={css.videoDiv} onClick={playAboutVideo}>
                    {isVideoPlaying ? (
                      <div className={css.videoPlayerWrapper}>
                        <iframe
                          id="rw-about-vid"
                          ref={videoRef}
                          src="https://player.vimeo.com/video/861347847?h=510f922945&badge=0&autopause=0&player_id=0&app_id=58479"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          title="RareWaters Brand Film"
                          data-ready="true"
                          className={`${css.videoStyle} ${css.showVideo}`}
                          onLoadedData={handleVideoLoaded}
                        ></iframe>
                      </div>
                    ) : (
                      <div className={css.videoPoster}>
                        <img src={RareWatersPoster} alt="PosterImg" className={css.posterImage} />
                      </div>
                    )}
                  </div>
                  <hr className={css.hrStyle} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: richTextEditor?.replace(/[\uff1c\uff1e]/g, ch => unescape[ch]),
                    }}
                    className={css.richTextEditor}
                  ></div>
                </section>
              ) : null}

              <div className={css.missionMessage}>
                <p>
                  Most of our properties are ranches, so expect to walk through hayfields, cow
                  fields, or even hop a few fences. These are not "groomed" properties. They can be
                  rugged and wild and we believe that is part of the adventure with RareWaters! If
                  you have specific questions or concerns, please reach out in advance of booking a
                  trip!
                </p>
              </div>
              {/* <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                intl={intl}
              /> */}
              {fetchListingUsgsInProgress ? (
                <Skeleton width={'100%'} height={118} />
              ) : (
                <SectionRiverData
                  id="listing-river-data"
                  riverDataObject={listingUsgs?.riverData}
                  fetchRiverDataError={fetchListingUsgsError}
                />
              )}
              {fetchWeatherDataInProgress ? (
                <Skeleton width={'100%'} height={118} />
              ) : (
                <SectionWeatherForecast
                  id="listing-weather-forecast"
                  fetchWeatherForecastError={fetchWeatherDataError}
                  forecast={weatherData}
                />
              )}

              <SectionMapMaybe
                id="listing-map"
                geolocation={geolocation}
                publicData={publicData}
                listingId={currentListing.id}
                mapsConfig={config.maps}
              />

              {fetchReviewsInProgress ? (
                <Skeleton width={'100%'} height={118} />
              ) : (
                <SectionReviews
                  id="listing-reviews"
                  reviews={reviews}
                  fetchReviewsError={fetchReviewsError}
                />
              )}

              {/* <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            /> */}
            </div>
            <div className={css.orderColumnForProductLayout}>
              {!fetchAvailableSlotsInProgress ? (
                <OrderPanel
                  className={css.productOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  onSubmit={handleOrderSubmit}
                  isValid1xUsage={isValid1xUsage}
                  authorLink={
                    <NamedLink
                      className={css.authorNameLink}
                      name="ListingPage"
                      params={params}
                      to={{ hash: '#author' }}
                    >
                      {authorDisplayName}
                    </NamedLink>
                  }
                  title={
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  }
                  titleDesktop={
                    <H4 as="h1" className={css.orderPanelTitle}>
                      <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                    </H4>
                  }
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={onContactUser}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  marketplaceCurrency={config.currency}
                  dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                  marketplaceName={config.marketplaceName}
                  dailyPrices={dailyPrices}
                  availableSlots={availableSlots}
                  maxGuestCount={maxGuestCount}
                  features={features}
                />
              ) : (
                <Skeleton style={{ heigth: '500px' }} />
              )}

              <div className={css.bookmarkContainer}>
                {showBookmarkButton() && (
                  <SecondaryButton className={css.likeButton} onClick={onClickBookmark}>
                    {setBookmarkButtonContent()}
                  </SecondaryButton>
                )}
                <span
                  className={css.bookmarkedSpan}
                >{`${bookmarkCount} people bookmarked this place`}</span>
                {typeof window !== 'undefined' && (
                  <>
                    <div className={css.shareContainer}>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={'Share'}
                        hashtag={'#RareWaters'}
                        description={'RareWaters'}
                      >
                        <FacebookIcon size={36} round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        title={title}
                        url={window.location.href}
                        hashtag={'#RareWaters'}
                      >
                        <TwitterIcon size={36} round />
                      </TwitterShareButton>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {listingsOfLastBookings && (
            <ReviewsGrid
              title={anglersAlsoTitle}
              backgroundColorRoot={'#E7F1F7'}
              cardsData={listingsOfLastBookings}
              alignItems={'center'}
              textAlign="center"
              backgroundColor={'#E7F1F7'}
            />
          )}

          <FrequentlyAskedQuestions items={faqItems} backgroundColorCollapse="transparent" />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  riverDataObject: null,
  fetchRiverDataError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
  isValid1xUsage: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  riverDataObject: objectOf(propTypes.riverDataObject),
  fetchRiverDataError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  listingsOfLastBookings: array,
  fetchListingsOfLastBookingsInProgress: bool.isRequired,
  fetchListingsOfLastBookingsError: propTypes.error,
  updateBookmarkInProgress: bool.isRequired,
  isValid1xUsage: bool.isRequired,
  initiate1xUsageBookingValidator: func.isRequired,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { features } = state.FeatureControlPage;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    listingsOfLastBookings,
    fetchListingsOfLastBookingsInProgress,
    fetchListingsOfLastBookingsError,
    updateBookmarkInProgress,
    dailyPrices,
    availableSlots,
    listingUsgs,
    fetchListingUsgsInProgress,
    fetchListingUsgsError,
    weatherData,
    fetchWeatherDataInProgress,
    fetchWeatherDataError,
    fetchAvailableSlotsInProgress,
    fetchReviewsInProgress,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    listingsOfLastBookings,
    fetchListingsOfLastBookingsInProgress,
    fetchListingsOfLastBookingsError,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    updateBookmarkInProgress,
    dailyPrices,
    availableSlots,
    listingUsgs,
    fetchListingUsgsInProgress,
    fetchListingUsgsError,
    weatherData,
    fetchWeatherDataInProgress,
    fetchWeatherDataError,
    fetchAvailableSlotsInProgress,
    fetchReviewsInProgress,
    features,
    isValid1xUsage: isValid1xUsage(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onUpdateBookmark: (liked, listingId, currentUserId, config) =>
    dispatch(updateBookmark(liked, listingId, currentUserId, config)),
  initiate1xUsageBookingValidator: userId => dispatch(initiate1xUsageBookingValidator(userId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
